import React, { ChangeEvent, memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { Modal } from 'Components/Modal';
import { Label } from 'Components/Label';
import { TextBox } from 'Components/TextBox';
import { PurpleButton } from 'Components/Button';

import { ValidateBackGround } from 'Components/Validation';

import formClasses from 'Themes/form/form.module.css';
import { RocketPayToast } from 'Components/RockeyPay/RocketPayToast';
import classes from './requestPaymentModal.module.css';

interface Props {
  // modal variables
  isOpen: boolean;
  modalCloseClick: () => void;
  onRequestButtonClick: (e: any) => void;
  hasErrors: { email: boolean; description: boolean; amount: boolean };
  canProceed: boolean;
  email: string;
  setEmail: (e: ChangeEvent<HTMLInputElement>) => void;
  checkEmail: () => void;
  description: string;
  setDescription: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  checkDescription: () => void;
  amount: string;
  setAmount: (e: ChangeEvent<HTMLInputElement>) => void;
  checkAmount: () => void;
  showToast: boolean;
  toastMessage: string;
  toastError: boolean;
}

function RequestPaymentModal({
  isOpen,
  onRequestButtonClick,
  modalCloseClick,
  hasErrors,
  canProceed,
  email,
  setEmail,
  checkEmail,
  description,
  setDescription,
  checkDescription,
  amount,
  setAmount,
  checkAmount,
  showToast,
  toastMessage,
  toastError,
}: Props) {
  return (
    <Modal
      id="request-payment-modal"
      classes={classes}
      title="Request Payment"
      isOpen={isOpen}
      modalHeader
      dataBsBackdrop="static"
      dataBsKeyboard="false"
      modalCloseClick={modalCloseClick}
      toast={<RocketPayToast showToast={showToast} message={toastMessage} error={toastError} />}
    >
      <form
        className={`requires-validation g-3 ${formClasses.formBase} ${classes.container}`}
        noValidate
        action="#"
        onSubmit={onRequestButtonClick}
      >
        <ValidateBackGround isValid={!hasErrors.email}>
          <Label ariaLabel="Email" className={formClasses.label} htmlFor="email">
            Email
          </Label>
          <TextBox
            value={email}
            name="email"
            type="text"
            className={`mb-0 pb-0 ${formClasses.validateField} ${
              hasErrors.email ? formClasses.invalidField : formClasses.validField
            } ${hasErrors.email ? 'is-invalid' : ''}`}
            required
            placeholder="john@example.com"
            ariaLabel="Enter email address"
            autoComplete="off"
            onChange={setEmail}
            onBlur={checkEmail}
          />
        </ValidateBackGround>
        <ValidateBackGround isValid={!hasErrors.amount}>
          <Label ariaLabel="Amount" className={formClasses.label} htmlFor="amount">
            Amount
          </Label>
          <TextBox
            value={amount}
            type="text"
            name="amount"
            className={`mb-0 pb-0 ${formClasses.validateField} ${
              hasErrors.amount ? formClasses.invalidField : formClasses.validField
            } ${hasErrors.amount ? 'is-invalid' : ''}`}
            required
            placeholder=""
            ariaLabel="Enter amount"
            autoComplete="off"
            onChange={setAmount}
            onBlur={checkAmount}
          />
        </ValidateBackGround>
        <ValidateBackGround isValid={!hasErrors.description}>
          <Label ariaLabel="Description" className={formClasses.label} htmlFor="description">
            Description
          </Label>
          <textarea
            value={description}
            name="description"
            className={`mb-0 pb-0 ${formClasses.validateField} ${
              hasErrors.description ? formClasses.invalidField : formClasses.validField
            } ${hasErrors.description ? 'is-invalid' : ''}`}
            required
            placeholder=""
            aria-label="Description"
            autoComplete="off"
            onChange={setDescription}
            onBlur={checkDescription}
          />
        </ValidateBackGround>
        <br />
        <div className={`modal-footer ${classes.modalFooter}`}>
          <PurpleButton onClick={modalCloseClick} className={classes.cancelButton}>
            Cancel
          </PurpleButton>
          <PurpleButton onClick={onRequestButtonClick} disabled={!canProceed} className={classes.requestButton}>
            Request Payment
          </PurpleButton>
        </div>
      </form>
    </Modal>
  );
}

const RequestPaymentModalMemo = memo(RequestPaymentModal, areEqual);

export { RequestPaymentModalMemo as RequestPaymentModal };
