import React, { memo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { ProjectsTabs } from 'Containers/Projects/ProjectsTabs';
import { useHistory } from 'react-router-dom';
import { setSelectedProjectId, setProjectInfoCleared } from 'Containers/Projects/actions';
import { clearRoomsObject } from 'Containers/RocketScan/RoomsView/Rooms/actions';
import { clearReportsList } from 'Containers/ReportsAndDocuments/Reports/actions';
import { setLocation, listLocationTypes } from 'Containers/RocketScan/MultiUnit/Locations/actions';

import {
  listPropertyTypes,
  listDamageTypes,
  listRoomNotesCategories,
  listUnitOfMeasurementTypes,
  listScopeActionTypes,
} from 'Containers/RocketScan/actions';

import {
  propertyTypesSelector,
  categoriesSelector,
  damageTypesSelector,
  unitOfMeasurementTypesSelector,
  scopeActionTypesSelector,
} from 'Containers/RocketScan/selectors';
import { locationTypesSelector } from 'Containers/RocketScan/MultiUnit/Locations/selectors';

function ProjectsContainer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const propertyTypes = useSelector(propertyTypesSelector, areEqual);
  const damageTypes = useSelector(damageTypesSelector, areEqual);
  const locationTypes = useSelector(locationTypesSelector, areEqual);
  const roomNotesCategories = useSelector(categoriesSelector, areEqual);
  const unitOfMeasurementTypes = useSelector(unitOfMeasurementTypesSelector, areEqual);
  const scopeActionTypes = useSelector(scopeActionTypesSelector, areEqual);

  // preload project APIs that don't depend on a project id
  useEffect(() => {
    if (propertyTypes.length === 0) {
      dispatch(listPropertyTypes());
    }
    if (damageTypes.length === 0) {
      dispatch(listDamageTypes());
    }
    if (locationTypes.length === 0) {
      dispatch(listLocationTypes());
    }
    if (roomNotesCategories.length === 0) {
      dispatch(listRoomNotesCategories());
    }
    if (unitOfMeasurementTypes.length === 0) {
      dispatch(listUnitOfMeasurementTypes());
    }
    if (scopeActionTypes.length === 0) {
      dispatch(listScopeActionTypes());
    }
  }, []);

  // clear project-specific data when switching projects
  // - rooms data on rooms view
  // - list of reports
  useEffect(() => {
    dispatch(clearRoomsObject());
    dispatch(setLocation(undefined));
    dispatch(setProjectInfoCleared(true));

    dispatch(clearReportsList());
  }, []);

  const onClickRow = useCallback((e: any) => {
    const {
      dataset: { id },
    } = e.currentTarget;

    dispatch(setSelectedProjectId(id));
    history.push(`/projects/${id}/rocketscan`);
  }, []);

  return <ProjectsTabs onClickRow={onClickRow} />;
}

const ProjectsContainerMemo = memo(ProjectsContainer, areEqual);

export { ProjectsContainerMemo as ProjectsContainer };
