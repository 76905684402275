import React, { memo, useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { paymentsSelector } from 'Containers/RocketPay/selectors';

import { getPaymentList } from 'Containers/RocketPay/actions';

import { RocketPayPayments } from 'Components/RocketPay';

import { TabContent } from 'Components/Tabs';
import { usePaymentFunctions } from 'Context/RocketPay';

type PaymentDateRange = {
  startDate: string;
  endDate: string;
};

export type PaymentFilters = {
  startsBetween?: PaymentDateRange;
  email?: string;
  payment_status?: string;
};

interface Props {
  isActive: boolean;
}

function RocketPayPaymentsContainer({ isActive }: Props) {
  const dispatch = useDispatch();
  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);
  const payments = useSelector(paymentsSelector, areEqual);

  const [sortBy, setSortBy] = useState('-created_at');
  const [pageCount, setPageCount] = useState(1);
  const [initialPage, setInitialPage] = useState(0);

  const { emailFilters, statusFilters, savedEmailFilters, savedStatusFilters, savedDateFilter }: any =
    usePaymentFunctions();

  // sort by: -field === DESC, field === ASC
  // prefix with '-' for DESC
  const onClickSort = useCallback(
    (sort: string) => {
      if (sortBy.includes('-') && sortBy === sort) {
        setSortBy(sort.replace('-', ''));
      } else {
        setSortBy(sort);
      }
      setInitialPage(0);
    },
    [sortBy]
  );

  // set meta data
  useEffect(() => {
    if (payments?.data?.length > 0) {
      const { meta } = payments;
      const { total } = meta;

      setPageCount(total <= 15 ? 1 : Number(total / 15));
    }
  }, [payments]);

  const getPaymentStatusFilter = (filter: string[]) => {
    const statuses = statusFilters.filter((status) => filter.includes(status.id));
    return statuses.map((status) => status.name).join(',');
  };

  const getEmailFilter = (filter: string[]) => {
    const emails = emailFilters.filter((email) => filter.includes(email.id));
    return emails.map((email) => email.name).join(',');
  };

  const getPayments = useCallback(() => {
    const filters: PaymentFilters = {};
    if (savedDateFilter) {
      filters.startsBetween = savedDateFilter;
    }
    if (savedStatusFilters.length) {
      filters.payment_status = getPaymentStatusFilter(savedStatusFilters);
    }
    if (savedEmailFilters.length) {
      filters.email = getEmailFilter(savedEmailFilters);
    }
    dispatch(getPaymentList(firstCompanyId, initialPage + 1, sortBy, filters));
  }, [firstCompanyId, sortBy, initialPage, savedEmailFilters, savedStatusFilters, savedDateFilter]);

  const onPageChange = useCallback(({ selected: page }: any) => {
    setInitialPage(page);
    getPayments();
  }, []);

  // initial API call
  useEffect(() => {
    if (firstCompanyId) {
      getPayments();
    }
  }, [firstCompanyId, sortBy, initialPage, savedEmailFilters, savedStatusFilters, savedDateFilter]);

  return (
    <TabContent key="tab-content-rocketpay-payments" id="rocketpaypayments" className="show active position-relative">
      <RocketPayPayments
        payments={payments}
        getPayments={getPayments}
        isActive={isActive}
        sortBy={sortBy}
        onClickSort={onClickSort}
        pageCount={pageCount}
        initialPage={initialPage}
        onPageChange={onPageChange}
      />
    </TabContent>
  );
}

const RocketPayPaymentsContainerMemo = memo(RocketPayPaymentsContainer, areEqual);

export { RocketPayPaymentsContainerMemo as RocketPayPayments };
