import { handleApiRequest } from 'Utils/handleApiRequest';
import { PaymentFilters } from 'Containers/RocketPay/RocketPayPayments/RocketPayPayments';

export const SET_ROCKETPAY_PAYMENT_LIST = 'SET_ROCKETPAY_PAYMENT_LIST';
export const RESET_ROCKETPAY_PAYMENT_LIST = 'RESET_ROCKETPAY_PAYMENT_LIST';

export const SET_EMAIL_FILTERS = 'SET_EMAIL_FILTERS';
export const SET_STATUS_FILTERS = 'SET_STATUS_FILTERS';

interface ActionTypes {
  SET_ROCKETPAY_PAYMENT_LIST: any[];
  RESET_ROCKETPAY_PAYMENT_LIST: any;
  SET_EMAIL_FILTERS: string[];
  SET_STATUS_FILTERS: string[];
}

interface MessageAction {
  type: keyof ActionTypes;
  payload: any;
}

export type RocketPayTypes = MessageAction;

/*
 * NON-API THUNKS
 * */
export const resetPaymentList = () => async (dispatch: any) => {
  dispatch({
    type: RESET_ROCKETPAY_PAYMENT_LIST,
  });
};

/*
 * API THUNKS
 * */
/* eslint-disable */
export const getRocketPayStatus =
  (companyId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(dispatch, utils.Api.get(`/companies/${companyId}/rocket-pay`));
  };

export const createJustiFiBusinessForCompany =
  (companyId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(dispatch, utils.Api.post(`/companies/${companyId}/rocket-pay`));
  };

export const addBankAccountToRocketPay =
  (
    companyId: number,
    accountOwnerName: string,
    accountType: string,
    accountNumber: string,
    routingNumber: string,
    bankName?: string
  ) =>
  async (dispatch: any, _getState = null, utils: any) => {
    let requestData = {
      account_owner_name: accountOwnerName,
      account_type: accountType,
      account_number: accountNumber,
      routing_number: routingNumber,
    };

    if (bankName) {
      requestData['bank_name'] = bankName;
    }

    return await handleApiRequest(
      dispatch,
      utils.Api.post(`/companies/${companyId}/rocket-pay/add-bank-account`, requestData)
    );
  };

export const acceptJustiFiTerms =
  (companyId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(dispatch, utils.Api.post(`/companies/${companyId}/rocket-pay/accept`));
  };

export const getPaymentList =
  (companyId: number, page: number, sortBy: string, filters: PaymentFilters) =>
  async (dispatch: any, _getState = null, utils: any) => {
    console.log('getPaymentList', sortBy, page);

    const params = {
      page,
      sort: sortBy,
      'filter[email]': filters.email,
      'filter[payment_status]': filters.payment_status
    };

    if (filters.startsBetween) {
      params['filter[created_at]'] = `${filters.startsBetween.startDate},${filters.startsBetween.endDate}`;
    }

    const response = await handleApiRequest(
      dispatch,
      utils.Api.get(`/companies/${companyId}/rocket-pay/payments`, {
        params,
      })
    );

    if (response?.data) {
      dispatch({
        type: SET_ROCKETPAY_PAYMENT_LIST,
        payload: {
          data: response.data,
          meta: response.meta ?? {},
          links: response.links ?? {},
        },
      });
    }
  };

export const getRocketPayWebComponentToken =
  (companyId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(dispatch, utils.Api.get(`/companies/${companyId}/rocket-pay/web-component-token`));
  };

export const syncRocketPayAccountInfo =
  (companyId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(dispatch, utils.Api.post(`/companies/${companyId}/rocket-pay/synchronize`));
  };

export const listEmailsForPayments =
  (companyId: number, searchValue = '') =>
    async (dispatch: any, _getState = null, utils: any) => {
      let lastPage = false;
      let currentEmails = [];
      let page = 1;

      while (!lastPage) {
        const response = await handleApiRequest(
          dispatch,
          utils.Api.get(`companies/${companyId}/rocket-pay/payment/emails`, {
            params: {
              'filter[email]': searchValue,
              limit: 100,
              page
            },
          })
        );

        lastPage = response?.meta?.current_page === response?.meta?.last_page;

        page += 1;
        currentEmails.push(...response.data.map(((data: { email:string }, index:number) => {
          return {
            id: index + 1,
            name: data.email
          }
        })));
      }
      dispatch({
        type: SET_EMAIL_FILTERS,
        payload: currentEmails,
      });
    };

export const listStatusesForPayments =
  (companyId: number, searchValue = '') =>
    async (dispatch: any, _getState = null, utils: any) => {
      let lastPage = false;
      let currentStatuses:string[] = [];
      let page = 1;

      while (!lastPage) {
        const response = await handleApiRequest(
          dispatch,
          utils.Api.get(`companies/${companyId}/rocket-pay/payment/statuses`, {
            params: {
              'filter[payment_status]': searchValue,
              limit: 100,
              page
            },
          })
        );

        lastPage = response?.meta?.current_page === response?.meta?.last_page;

        page += 1;
        if (response?.data) {
          currentStatuses.push(...response.data.map(((status:string, index:number) => {
            return {
              id: index + 1,
              name: status
            }
          })));
        }
      }
      dispatch({
        type: SET_STATUS_FILTERS,
        payload: currentStatuses,
      });
    };

export const testProvisionPayment =
  (companyId: number) =>
  async (dispatch: any, _getState = null, utils: any) => {
    return await handleApiRequest(
      dispatch,
      utils.Api.post(`/companies/${companyId}/rocket-pay/test-provision-payment`)
    );
  };
