import React, { memo, useCallback, useState } from 'react';

import { PurpleButton } from 'Components/Button/PurpleButton';

import { areEqual } from 'Utils/equalityChecks';
import { RequestPaymentModal, PaymentList, PaymentFilters } from 'Containers/RocketPay';

import classes from './rocketPayPayments.module.css';


interface Props {
  payments: any;
  getPayments: (e?: any) => void;
  isActive: boolean;
  sortBy?: string;
  onClickSort?: (sort: string) => void;
  initialPage?: number;
  pageCount?: number;
  onPageChange?: (e: any) => void;
}

function RocketPayPayments({ payments, getPayments, isActive, sortBy, onClickSort, initialPage, pageCount, onPageChange}: Props) {
  const [isRequestingPayment, setIsRequestingPayment] = useState(false);

  const requestPaymentClick = useCallback(() => {
    setIsRequestingPayment(true);
  }, [setIsRequestingPayment]);

  return (
    <div className={classes.rocketPayContent}>
      <div className={`d-flex justify-content-between align-items-center ${classes.contentHeader}`}>
        <h2>RocketPay</h2>
        {!isRequestingPayment && isActive && (
          <PurpleButton className={classes.requestButton} onClick={requestPaymentClick}>
            Request Payment
          </PurpleButton>
        )}
      </div>
      {!isActive && (
        <div>
          <h4>Pending approval for requesting payments</h4>
        </div>
      )}
      <PaymentFilters/>
      <PaymentList
        payments={payments}
        onClickSort={onClickSort}
        sortBy={sortBy}
        initialPage={initialPage}
        pageCount={pageCount}
        onPageChange={onPageChange}
      />
      {isRequestingPayment && (
        <RequestPaymentModal
          isOpen={isRequestingPayment}
          setIsOpen={setIsRequestingPayment}
          getPayments={getPayments}
        />
      )}
    </div>
  );
}

const RocketPayPaymentsMemo = memo(RocketPayPayments, areEqual);

export { RocketPayPaymentsMemo as RocketPayPayments };
