export const navItems = [
  {
    id: 1,
    title: 'Dashboard',
    path: '/dashboard',
    icon: 'dashboard',
    adminOnly: false,
  },
  {
    id: 2,
    title: 'Projects',
    path: '/projects',
    icon: 'projects',
    adminOnly: false,
  },
  {
    id: 3,
    title: 'People',
    path: '/people',
    icon: 'people',
    adminOnly: false,
  },
  {
    id: 4,
    title: 'Forms',
    path: '/form',
    icon: 'projects',
    adminOnly: false,
  },
  {
    id: 5,
    title: 'Timesheet',
    path: '/timesheet',
    icon: 'timesheet',
    adminOnly: false,
  },
  {
    id: 6,
    title: 'Metrics',
    path: '/metrics',
    icon: 'projects',
    adminOnly: true,
  },
  {
    id: 7,
    title: 'Equipment Inventory',
    path: '/equipment',
    icon: 'projects',
    adminOnly: false,
  },
  {
    id: 8,
    title: 'RocketCall',
    path: '/rocketcall',
    icon: 'phone',
    adminOnly: true,
  },
  {
    id: 9,
    title: 'RocketPay',
    path: '/rocketpay',
    icon: 'rocketpay',
    adminOnly: false,
  },
  {
    id: 10,
    title: 'RocketSketch',
    path: '/rocketsketch',
    icon: 'rocketsketch',
    adminOnly: false,
  },
  {
    id: 11,
    title: 'RocketTime',
    path: '/rockettime',
    icon: 'timesheet',
    adminOnly: false,
  },
  // {
  //   id: 5,
  //   title: 'RocketEstimate',
  //   path: '/rocket_estimate',
  //   icon: 'projects',
  // },
  {
    id: 12,
    title: 'Rocket Scope',
    path: '/rocketscope',
    icon: 'scope',
    adminOnly: false,
  },
];
