import React, { memo, useEffect, useState } from 'react';
import { areEqual } from 'Utils/equalityChecks';
import { RocketCallTabsContainer } from 'Containers/RocketCall';
import { RocketCallBlocker } from 'Components/RocketCall';
import { useDispatch, useSelector } from 'react-redux';
import { handleApiRequest } from 'Utils/handleApiRequest';
import { Api } from 'Utils/api';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { RocketCallModel } from 'Containers/User/Models/RocketCallModel/RocketCallModel';

enum RocketCallShow {
    Initial,
    Waiting,
    Blocker,
    TabContainer
}

function RocketCallContainer() {

    const dispatch = useDispatch();
    const [screen, setScreen] = useState(RocketCallShow.Initial);
    const [rocketCall, setRocketCall] = useState<RocketCallModel>(null);

    const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

    useEffect(() => {
        const fetchData = async () => {
            const response = await handleApiRequest(dispatch,  Api.get(`companies/${firstCompanyId}/rocket-call`));
            if (response.data) {
                if (response.data.is_active) {
                    setRocketCall(response.data);
                    setScreen(RocketCallShow.TabContainer);
                } else {
                    setScreen(RocketCallShow.Blocker);
                }
            } else {
                console.log('Error getting packages');
            }
        }
        if (screen === RocketCallShow.Initial) {
            setScreen(RocketCallShow.Waiting);
            void fetchData();
        }
    }, []);

    switch (screen) {
        case RocketCallShow.Blocker:
            return <RocketCallBlocker/>;
        case RocketCallShow.TabContainer:
            return <RocketCallTabsContainer rocketCall={rocketCall} />;
        default:
            return null; // Show waiting?
    }
}

const RocketCallContainerMemo = memo(RocketCallContainer, areEqual);

export { RocketCallContainerMemo as RocketCallContainer };
