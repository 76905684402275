import React, { memo, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { areEqual } from 'Utils/equalityChecks';

import { useUser } from 'Context/User';
import { UserModel } from 'Containers/User/Models/UserModel';

import { TabContent } from 'Components/Tabs';

import { listEmployees } from 'Containers/Crew/actions';

import { employeesSelector } from 'Containers/Crew/selectors';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';

import { RocketCallSetupTab } from 'Components/RocketCall';
import { RocketCallModel } from 'Containers/User/Models/RocketCallModel';

interface Props {
  rocketCall: RocketCallModel;
}
function RocketCallSetupTabContainer({ rocketCall }: Props) {
  const dispatch = useDispatch();

  const user = useUser();
  const { id: userId }: UserModel = user;

  const { companies } = user;

  const employees = useSelector(employeesSelector, areEqual);
  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

  const [showDropDown, setShowDropDown] = useState(false);
  const [showStartTimePicker, setShowStartTimePicker] = useState(false);
  const [showEndTimePicker, setShowEndTimePicker] = useState(false);
  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedEmployeeName, setSelectedEmployeeName] = useState(rocketCall.on_call_employee.full_name);
  const [startTime, setStartTime] = useState(rocketCall.hours_of_operation_start);
  const [endTime, setEndTime] = useState(rocketCall.hours_of_operation_end);
  const [forwardPhoneNumber, setForwardPhoneNumber] = useState(rocketCall.forward_phone_number);

  // api call
  const getEmployees = useCallback(async () => {
    await dispatch(listEmployees(firstCompanyId, userId));
  }, [firstCompanyId, userId]);

  // initial fetch
  useEffect(() => {
    (async function fetchData() {
      await getEmployees();
    })();
  }, []);

  const onClickIcon = useCallback(() => {
    setShowDropDown((prevState) => !prevState);
  }, []);

  const onClickStartTimeBox = useCallback(() => {
    setShowStartTimePicker((prevState) => !prevState);
  }, []);

  const onClickEndTimeBox = useCallback(() => {
    setShowEndTimePicker((prevState) => !prevState);
  }, []);

  const onCloseStartTimePicker = useCallback(() => {
    setShowStartTimePicker(false);
  }, []);

  const onCloseEndTimePicker = useCallback(() => {
    setShowEndTimePicker(false);
  }, []);

  const onSelectItem = useCallback(
    (id: string) => {
      if (employees.data?.length > 0) {
        const employee = employees.data.find((employee: any) => employee.id === id);

        setSelectedEmployeeName(employee.full_name);
        setSelectedValue(employee.id);
      }

      setShowDropDown(false);
    },
    [employees]
  );

  return (
    <TabContent key="tab-content-rocketcall-setup" id="rocketcallsetup" className="show active position-relative">
      <RocketCallSetupTab
        companies={companies}
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
        forwardPhoneNumber={forwardPhoneNumber}
        employees={employees}
        selectedValue={selectedValue}
        selectedEmployeeName={selectedEmployeeName}
        showDropDown={showDropDown}
        showStartTimePicker={showStartTimePicker}
        showEndTimePicker={showEndTimePicker}
        onClickStartTimeBox={onClickStartTimeBox}
        onClickEndTimeBox={onClickEndTimeBox}
        onSelectItem={onSelectItem}
        onClickIcon={onClickIcon}
        onCloseStartTimePicker={onCloseStartTimePicker}
        onCloseEndTimePicker={onCloseEndTimePicker}
      />
    </TabContent>
  );
}

const RocketCallSetupTabContainerMemo = memo(RocketCallSetupTabContainer, areEqual);

export { RocketCallSetupTabContainerMemo as RocketCallSetupTabContainer };
