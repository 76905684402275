import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { RocketPayCompanyModel } from 'Containers/User/Models/RocketPayModel/RocketPayCompanyModel';

import { RocketPayPayments, RocketPayStatus } from 'Containers/RocketPay';

import { RocketPayTabs } from 'Components/RocketPay';

interface Props {
  companyInfo: RocketPayCompanyModel;
}

function RocketPayDashboardContainer({ companyInfo }: Props) {
  return (
    <RocketPayTabs id="rocketpay-tabs">
      <RocketPayPayments isActive={companyInfo.active}  />
      <RocketPayStatus companyInfo={companyInfo} />
    </RocketPayTabs>
  );
}

const RocketPayDashboardContainerMemo = memo(RocketPayDashboardContainer, areEqual);
export { RocketPayDashboardContainerMemo as RocketPayDashboard };
