// types
import {
  RocketPayTypes,
  SET_ROCKETPAY_PAYMENT_LIST,
  RESET_ROCKETPAY_PAYMENT_LIST,
  SET_EMAIL_FILTERS,
  SET_STATUS_FILTERS
} from 'Containers/RocketPay/actions';

// state
const initialState = {
  payments: { data: [], meta: {}, links: {} },
  emailFilters: [],
  statusFilters: []
};

export const rocketPayReducer = (state = initialState, action: RocketPayTypes) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ROCKETPAY_PAYMENT_LIST:
      return {
        ...state,
        payments: payload,
      };
    case RESET_ROCKETPAY_PAYMENT_LIST:
      return {
        ...state,
        payments: initialState.payments,
      };
    case SET_EMAIL_FILTERS:
      return {
        ...state,
        emailFilters: payload,
      }
    case SET_STATUS_FILTERS:
      return {
        ...state,
        statusFilters: payload,
      }
    default:
      return state;
  }
};
