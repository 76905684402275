import React, { memo, useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';
import { debounce } from 'Utils/debounce';

import { usePaymentFunctions } from 'Context/RocketPay';

import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { listEmailsForPayments, listStatusesForPayments } from 'Containers/RocketPay/actions';
import { PaymentFilters } from 'Components/RocketPay/PaymentsFilters';

const PaymentFiltersContainer = () => {
  const {
    // filter open status
    isEmailFilterOpen,
    isStatusFilterOpen,
    isDateRangeFilterOpen,
    setIsEmailFilterOpen,
    setIsStatusFilterOpen,
    setIsDateRangeFilterOpen,
    // list of filters to show
    // selected (but not saved) filters
    selectedStatusFilters,
    selectedEmailFilters,
    dateFilterStart,
    dateFilterEnd,
    // saved filters
    savedEmailFilters,
    savedStatusFilters,
    // filter functions
    onStatusFilterListItemClick,
    onEmailFilterListItemClick,
    applyEmailFilters,
    applyStatusFilters,
    onChangeDateFilter,
    applyDateFilters,
    clearStatusFilters,
    clearEmailFilters,
    clearDateFilter,
    isDateFilterApplied,
    emailFilters,
    statusFilters,
  }: any = usePaymentFunctions();

  const dispatch = useDispatch();

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

  // search box refs
  const emailSearchRef = useRef(undefined);
  const statusSearchRef = useRef(undefined);

  const [emailSearchValue, setEmailSearchValue] = useState('');
  const [statusSearchValue, setStatusSearchValue] = useState('');

  const onEmailFilterClick = useCallback(() => {
    setIsEmailFilterOpen((prevState) => !prevState);
    setIsStatusFilterOpen(false);
    setIsDateRangeFilterOpen(false);
  }, []);

  const onStatusFilterClick = useCallback(() => {
    setIsStatusFilterOpen((prevState) => !prevState);
    setIsEmailFilterOpen(false);
    setIsDateRangeFilterOpen(false);
  }, []);

  const onDateRangeFilterClick = useCallback(() => {
    setIsDateRangeFilterOpen((prevState) => !prevState);
    setIsEmailFilterOpen(false);
    setIsStatusFilterOpen(false);
  }, []);

  const onApplyFilterButtonClicked = useCallback(
    (e: any) => {
      e.preventDefault();
      const { id } = e.target;

      if (id === 'email') {
        applyEmailFilters();
        setIsEmailFilterOpen(false);
      } else if (id === 'status') {
        applyStatusFilters();
        setIsStatusFilterOpen(false);
      } else if (id === 'date') {
        applyDateFilters();
        setIsDateRangeFilterOpen(false);
      }
    },
    [applyEmailFilters, applyStatusFilters, applyDateFilters]
  );

  const onClearFilterButtonClicked = useCallback((e: any) => {
    e.preventDefault();
    const { id } = e.target;

    if (id === 'email') {
      clearEmailFilters();
      setIsEmailFilterOpen(false);
    } else if (id === 'status') {
      clearStatusFilters();
      setIsStatusFilterOpen(false);
    } else if (id === 'date') {
      clearDateFilter();
      setIsDateRangeFilterOpen(false);
    }
  }, []);

  // api call to list filters
  const getEmails = useCallback(
    (search = '') => {
      dispatch(listEmailsForPayments(firstCompanyId, search));
    },
    [firstCompanyId]
  );

  const getStatuses = useCallback(
    (search = '') => {
      dispatch(listStatusesForPayments(firstCompanyId, search));
    },
    [firstCompanyId]
  );

  // get email and project filters
  useEffect(() => {
    if (firstCompanyId) {
      getEmails();
      getStatuses();
    }
  }, [firstCompanyId]);

  // handle search box value change
  const handleEmailSearchValueChange = ({ target: { value } }: any) => {
    if (value.length <= 36) {
      setEmailSearchValue(value);
      if (value.length >= 2) {
        getEmails(value);
      }
      if (value.length === 0) {
        getEmails();
      }
    }
  };
  const handleStatusSearchValueChange = ({ target: { value } }: any) => {
    if (value.length <= 36) {
      setEmailSearchValue(value);
      if (value.length >= 2) {
        getStatuses(value);
      }
      if (value.length === 0) {
        getStatuses();
      }
    }
  };

  // debounce function on search value change
  const onChangeEmailSearchValue = useMemo(() => debounce(handleEmailSearchValueChange, 300), [firstCompanyId]);
  const onChangeStatusSearchValue = useMemo(() => debounce(handleStatusSearchValueChange, 300), [firstCompanyId]);

  const onClickEmailSearchClearButton = useCallback(() => {
    getEmails();
    setEmailSearchValue('');
    emailSearchRef.current.value = '';
    emailSearchRef.current.focus();
  }, [firstCompanyId, emailSearchRef]);

  const onClickStatusSearchClearButton = useCallback(() => {
    getStatuses();
    setStatusSearchValue('');
    statusSearchRef.current.value = '';
    statusSearchRef.current.focus();
  }, [firstCompanyId, statusSearchRef]);

  return (
    <PaymentFilters
      startDate={dateFilterStart}
      endDate={dateFilterEnd}
      isEmailFilterOpen={isEmailFilterOpen}
      isStatusFilterOpen={isStatusFilterOpen}
      isDateRangeFilterOpen={isDateRangeFilterOpen}
      // email filters
      emailFilters={emailFilters}
      selectedEmailFilters={selectedEmailFilters}
      emailSearchRef={emailSearchRef}
      emailSearchValue={emailSearchValue}
      savedEmailFiltersCount={savedEmailFilters.length}
      onChangeEmailSearchValue={onChangeEmailSearchValue}
      // status filters
      selectedStatusFilters={selectedStatusFilters}
      statusSearchRef={statusSearchRef}
      statusSearchValue={statusSearchValue}
      savedStatusFiltersCount={savedStatusFilters.length}
      onChangeStatusSearchValue={onChangeStatusSearchValue}
      statusFilters={statusFilters}
      // functions
      onEmailFilterClick={onEmailFilterClick}
      onEmailFilterListItemClick={onEmailFilterListItemClick}
      onClickEmailSearchClearButton={onClickEmailSearchClearButton}
      onStatusFilterClick={onStatusFilterClick}
      onStatusFilterListItemClick={onStatusFilterListItemClick}
      onClickStatusSearchClearButton={onClickStatusSearchClearButton}
      onDateRangeFilterClick={onDateRangeFilterClick}
      onChangeDate={onChangeDateFilter}
      onApplyFilterButtonClick={onApplyFilterButtonClicked}
      onClearFilterButtonClick={onClearFilterButtonClicked}
      onClearDateFilterButtonClick={onClearFilterButtonClicked}
      dateFilterApplied={isDateFilterApplied}
    />
  );
};

const PaymentFiltersContainerMemo = memo(PaymentFiltersContainer, areEqual);

export { PaymentFiltersContainerMemo as PaymentFilters };
