import React, { memo } from 'react';

import { Label } from 'Components/Label';
import { TextBox } from 'Components/TextBox';
import { Icon } from 'Components/Icons';

import { DropDown } from 'Components/DropDown';

import { areEqual } from 'Utils/equalityChecks';
import { PurpleButton } from 'Components/Button';
import { RocketPayToast } from 'Components/RockeyPay/RocketPayToast';

import formClasses from 'Themes/form/form.module.css';
import classes from './rocketPayBankInfoForm.module.css';

interface Props {
  accountOwner: string;
  accountType: string;
  accountTypeId: number;
  bankAccountTypes: any;
  accountNumber: string;
  routingNumber: string;
  bankName: string;
  showDropDown: boolean;
  loading: boolean;
  onChangeAccountOwner: (e: any) => void;
  onChangeAccountType: (e: any) => void;
  toggleAccountTypeDropdown: (e: any) => void;
  onChangeAccountNumber: (e: any) => void;
  onChangeRoutingNumber: (e: any) => void;
  onChangeBankName: (e: any) => void;
  onFormSubmit: (e: any) => void;
  // toast variables
  showToast: boolean;
  toastMessage: string;
  toastError: boolean;
}

function RocketPayBankInfoForm({
  accountOwner,
  accountType,
  accountTypeId,
  bankAccountTypes,
  accountNumber,
  routingNumber,
  bankName,
  showDropDown,
  loading,
  showToast,
  toastMessage,
  toastError,
  onChangeAccountOwner,
  onChangeAccountType,
  toggleAccountTypeDropdown,
  onChangeAccountNumber,
  onChangeRoutingNumber,
  onChangeBankName,
  onFormSubmit,
}: Props) {
  return (
    <div className={classes.sectionContainer}>
      <form className={classes.form} onSubmit={onFormSubmit}>
        <div className={classes.row}>
          <div className={classes.rowHalf}>
            <Label ariaLabel="Account Owner Name" className={formClasses.label} htmlFor="name">
              Account Owner Name
            </Label>
            <TextBox
              value={accountOwner}
              name="accountOwnerName"
              type="text"
              className={`mb-0 pb-0 ${classes.textBox}`}
              required
              placeholder=""
              ariaLabel="Please enter the account owner's name"
              onChange={onChangeAccountOwner}
              autoComplete="off"
            />
          </div>
          <div className={classes.rowHalf}>
            <Label ariaLabel="Account Type" className={formClasses.label} htmlFor="string">
              Account Type
            </Label>
            <div className={`dropdown ${classes.dropdownSelectBase}`}>
              <TextBox
                value={accountType}
                name="accountType"
                type="text"
                className={`mb-0 pb-0 ${classes.textBox}`}
                onChange={() => {}}
                onClick={toggleAccountTypeDropdown}
                required
                placeholder=""
                ariaLabel="Please enter the account type"
                autoComplete="off"
              />
              <Icon
                className={classes.icon}
                type={showDropDown ? 'caretup' : 'caretdown'}
                onClick={toggleAccountTypeDropdown}
              />
              <DropDown
                className={classes.dropDown}
                id="accountType"
                items={bankAccountTypes}
                selected={accountTypeId}
                onSelectItem={onChangeAccountType}
                showDropDown={showDropDown}
              />
            </div>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.rowHalf}>
            <Label ariaLabel="Account Number" className={formClasses.label} htmlFor="number">
              Account Number
            </Label>
            <TextBox
              value={accountNumber}
              name="accountNumber"
              type="text"
              className={`mb-0 pb-0 ${classes.textBox}`}
              required
              placeholder=""
              ariaLabel="Please enter the account number"
              onChange={onChangeAccountNumber}
              autoComplete="off"
            />
          </div>
          <div className={classes.rowHalf}>
            <Label ariaLabel="Routing Number" className={formClasses.label} htmlFor="number">
              Routing Number
            </Label>
            <TextBox
              value={routingNumber}
              name="routingNumber"
              type="text"
              className={`mb-0 pb-0 ${classes.textBox}`}
              required
              placeholder=""
              ariaLabel="Please enter the routing number"
              onChange={onChangeRoutingNumber}
              autoComplete="off"
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.rowHalf}>
            <Label ariaLabel="Bank Name" className={formClasses.label} htmlFor="number">
              Bank Name (Optional)
            </Label>
            <TextBox
              value={bankName}
              name="bankName"
              type="text"
              className={`mb-0 pb-0 ${classes.textBox}`}
              required
              placeholder=""
              ariaLabel="Please enter the bank name"
              onChange={onChangeBankName}
              autoComplete="off"
            />
          </div>
        </div>
        <br />
        <div className="d-flex w-100 justify-content-center">
          <PurpleButton
            onClick={onFormSubmit}
            className={classes.submitButton}
            disabled={!accountOwner || !accountType || !accountNumber || !routingNumber || loading}
          >
            Submit
          </PurpleButton>
        </div>
      </form>
      <RocketPayToast showToast={showToast} message={toastMessage} error={toastError} />
    </div>
  );
}

const RocketPayBankInfoFormMemo = memo(RocketPayBankInfoForm, areEqual);
export { RocketPayBankInfoFormMemo as RocketPayBankInfoForm };
