import React, { memo, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { RocketPayCompanyModel } from 'Containers/User/Models/RocketPayModel/RocketPayCompanyModel';
import { RocketPayStatus } from 'Components/RocketPay';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';

import { getRocketPayWebComponentToken } from '../actions';

interface Props {
  companyInfo: RocketPayCompanyModel;
}

function RocketPayStatusContainer({ companyInfo }: Props) {
  const dispatch = useDispatch();

  const [webToken, setWebToken] = useState('');

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

  const fetchWebComponentToken = useCallback(async () => {
    const response: any = await dispatch(getRocketPayWebComponentToken(firstCompanyId));
    if (response.web_component_token) {
      const token = response.web_component_token;
      setWebToken(token);
    }
  }, [firstCompanyId]);

  useEffect(() => {
    (async function fetchData() {
      await fetchWebComponentToken();
    })();
  }, [firstCompanyId]);

  return <RocketPayStatus companyInfo={companyInfo} authToken={webToken} />;
}

const RocketPayStatusContainerMemo = memo(RocketPayStatusContainer, areEqual);
export { RocketPayStatusContainerMemo as RocketPayStatus };
