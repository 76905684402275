import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';

import { getRocketPayWebComponentToken } from 'Containers/RocketPay/actions';

import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { RocketPayBusinessForm } from 'Components/RocketPay';

interface Props {
  businessId: string;
  successCallback: () => void;
}

function RocketPayBusinessFormContainer({ businessId, successCallback }: Props) {
  const dispatch = useDispatch();

  const [webToken, setWebToken] = useState('');

  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

  const fetchWebComponentToken = useCallback(async () => {
    const response: any = await dispatch(getRocketPayWebComponentToken(firstCompanyId));
    if (response.web_component_token) {
      const token = response.web_component_token;
      setWebToken(token);
    }
  }, [firstCompanyId]);

  useEffect(() => {
    (async function fetchData() {
      await fetchWebComponentToken();
    })();
  }, [firstCompanyId]);

  return <RocketPayBusinessForm businessId={businessId} authToken={webToken} successCallback={successCallback} />;
}

const RocketPayBusinessFormContainerMemo = memo(RocketPayBusinessFormContainer, areEqual);
export { RocketPayBusinessFormContainerMemo as RocketPayBusinessForm };
