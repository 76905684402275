import React, { memo, useCallback, useState, useRef } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { RocketPayToast } from 'Components/RockeyPay/RocketPayToast';

// eslint-disable-next-line
import '@justifi/webcomponents/dist/module/justifi-business-form.js';
// eslint-disable-next-line
import '@justifi/webcomponents/dist/webcomponents/webcomponents.css';

import classes from './rocketPayBusinessForm.module.css';

interface Props {
  businessId: string;
  authToken: string;
  successCallback: () => void;
}

function RocketPayBusinessForm({ businessId, authToken, successCallback }: Props) {
  // create the ref
  const formRef = useRef(null);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastError, setToastError] = useState(false);

  const refCallback = useCallback((ref) => {
    formRef.current = ref;

    const handleSubmit = (data) => {
      /* this event is raised when the server response is received */
      const response = data.detail;
      if (response?.data?.data?.id) {
        successCallback();
      } else if (response?.error) {
        const { message } = response.error;
        setToastMessage(`Error when submitting business info: ${message}`);
        setToastError(true);
        setShowToast(true);
      } else {
        setToastMessage('Unknown error when submitting business info');
        setToastError(true);
        setShowToast(true);
      }
    };

    if (ref) {
      ref.addEventListener('submitted', handleSubmit);
    }
    return () => {
      window.removeEventListener('submitted', handleSubmit);
    };
  }, []);

  return (
    <div className={classes.sectionContainer}>
      {businessId && authToken && (
        <justifi-business-form business-id={businessId} auth-token={authToken} ref={refCallback} />
      )}
      <RocketPayToast showToast={showToast} message={toastMessage} error={toastError} />
    </div>
  );
}

const RocketPayBusinessFormMemo = memo(RocketPayBusinessForm, areEqual);
export { RocketPayBusinessFormMemo as RocketPayBusinessForm };
