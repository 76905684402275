import React, { memo } from 'react';
import { areEqual } from 'Utils/equalityChecks';

import { Table, TableBody, TableColumn, TableHeader, TableRow, Th } from 'Components/Table';

import { Icon } from 'Components/Icons';
import { formatCurrency, formatDate } from 'Utils/helpers';

import classes from './paymentList.module.css';

interface Props {
  payments?: any;
  sortBy?: string;
  onClickSort?: (sort: string) => void;
}

function PaymentList({ payments, sortBy, onClickSort }: Props) {
  const hasPayments = payments?.data?.length > 0;

  return (
    <>
      <Table className={`table ${classes.paymentListWrapper}`}>
        <TableHeader>
          <TableRow>
            <Th>
               <span
                 className={classes.thSpan}
                 role="button"
                 tabIndex={-1}
                 onClick={() => onClickSort('-created_at')}
                 onKeyUp={() => onClickSort('-created_at')}
               >
              Date
                 {sortBy === '-created_at' ? (
                   <Icon className={classes.sortIcon} type="caretup"/>
                 ) : (
                   <Icon className={classes.sortIcon} type="caretdown"/>
                 )}
            </span>
            </Th>
            <Th>
               <span
                 className={classes.thSpan}
                 role="button"
                 tabIndex={-1}
                 onClick={() => onClickSort('-email')}
                 onKeyUp={() => onClickSort('-email')}
               >
              Email
                 {sortBy === '-email' ? (
                   <Icon className={classes.sortIcon} type="caretup"/>
                 ) : (
                   <Icon className={classes.sortIcon} type="caretdown"/>
                 )}
            </span>
            </Th>
            <Th>
              <span
                className={classes.thSpan}
                role="button"
                tabIndex={-1}
                onClick={() => onClickSort('-amount')}
                onKeyUp={() => onClickSort('-amount')}
              >
              Amount
                {sortBy === '-amount' ? (
                  <Icon className={classes.sortIcon} type="caretup"/>
                ) : (
                  <Icon className={classes.sortIcon} type="caretdown"/>
                )}
            </span>
            </Th>
            <Th>
              <span
                className={classes.thSpan}
                role="button"
                tabIndex={-1}
                onClick={() => onClickSort('-description')}
                onKeyUp={() => onClickSort('-description')}
              >
              Description
                {sortBy === '-description' ? (
                  <Icon className={classes.sortIcon} type="caretup"/>
                ) : (
                  <Icon className={classes.sortIcon} type="caretdown"/>
                )}
            </span>
            </Th>
            <Th>
              <span
                className={classes.thSpan}
                role="button"
                tabIndex={-1}
                onClick={() => onClickSort('-payment_status')}
                onKeyUp={() => onClickSort('-payment_status')}
              >
              Status
                {sortBy === '-payment_status' ? (
                  <Icon className={classes.sortIcon} type="caretup"/>
                ) : (
                  <Icon className={classes.sortIcon} type="caretdown"/>
                )}
            </span>
            </Th>
          </TableRow>
        </TableHeader>
        {hasPayments && (
          <TableBody>
            {payments.data.map((payment) => (
              <TableRow key={payment.id}>
                <TableColumn>
                  <div>{ formatDate(payment.updated_at, 'MMM d, yyyy h:mma') }</div>
                </TableColumn>
                <TableColumn>
                  <div>{payment.email}</div>
                </TableColumn>
                <TableColumn className={classes.columnContent}>
                  <div>
                    {formatCurrency(payment.amount / 100, payment.currency)} {payment.currency.toUpperCase()}
                  </div>
                </TableColumn>
                <TableColumn className={classes.columnContent}>
                  <div>{payment.description}</div>
                </TableColumn>
                <TableColumn className={classes.columnContent}>{payment.payment_response ?? 'unpaid'}</TableColumn>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {!hasPayments && (
        <div
          className={`d-flex justify-content-center align-items-center flex-column w-100 ${classes.noPaymentContent}`}>
          <p className={classes.noPaymentText}>No payment requests made yet.</p>
          <Icon type="rocketemblemtwo"/>
        </div>
      )}
    </>
  );
}

PaymentList.defaultProps = {
  payments: [],
  sortBy: null,
  onClickSort: null
};

const PaymentListMemo = memo(PaymentList, areEqual);

export { PaymentListMemo as PaymentList };
